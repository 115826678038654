<dft-header></dft-header>
<dft-nav></dft-nav>
<div class="dft-container">
  <div class="dft-inner-container">
    <div class="conteudo-container">
      <!-- Main content -->
      <section class="conteudo">
        <router-outlet></router-outlet>
      </section>
    </div>
    <dft-footer></dft-footer>
  </div>
</div>
