<form [formGroup]="form" class="container">
  <mat-label attr.aria-hidden="true">
    Órgão da Unidade
  </mat-label>
  <dft-multiselect
    formControlName="orgao"
    placeholder="Selecione o Órgão"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="listaDeOrgaos"
    ordenacao="descricao"
    campoDeTexto="bindName"
    permitirFiltroDePesquisa="true">
  </dft-multiselect>

  <br />

  <mat-label attr.aria-hidden="true">
    Dimensionamentos a consolidar
  </mat-label>
  <dft-multiselect
    [disabled]="!form.controls.orgao.value"
    formControlName="dimensionamentos"
    placeholder="Selecione dimensionamentos a consolidar"
    [delineado]="true"
    [selecaoUnica]="false"
    permitirFiltroDePesquisa="true"
    [listaDeOpcoes]="listaDeDimensionamentos"
    campoDeTexto="nome">
  </dft-multiselect>

  <mat-hint *ngIf="!form.controls.dimensionamentos.hasError('periodosDiferentes') || !form.controls.dimensionamentos.hasError('metodologiasDiferentes')">
    Para serem consolidados, os dimensionamentos devem pertencer ao mesmo período e à mesma metodologia
  </mat-hint>
  <mat-error *ngIf="form.controls.dimensionamentos.hasError('periodosDiferentes')">
    Os dimensionamentos selecionados não pertencem ao mesmo período
  </mat-error>
  <mat-error *ngIf="form.controls.dimensionamentos.hasError('metodologiasDiferentes')">
    Os dimensionamentos selecionados não pertencem à mesma metodologia
  </mat-error>
  <mat-error *ngIf="form.controls.dimensionamentos.hasError('unicaSelecao')">
    Selecione pelo menos dois dimensionamentos
  </mat-error>
</form>
<br />  
