import { DatePipe } from '@angular/common';
import { AtividadeCriterio } from '../models/atividade-criterio';

export function getStringSemAcento(str: string) {
  if (!str) {
    return '';
  }
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();
}

export function getStringSemPontos(str: string) {
  if (!str) {
    return '';
  }
  return str
    .toLowerCase()
    .replace('.', '')
    .trim();
}

export function getStringCPFSemMascara(str: string) {
  return str
    .replace(/\.*-*/g, '')
    .trim();
}

export function getPrimeiraMaiuscula(str: string) {
  return str[0].toUpperCase() + str.substr(1);
}

/**
 * Trata texto passado de forma a deixar somente primeira letra de cada palavra maiúscula.
 *
 * Exemplos:
 *    "FULANO DE TAL" ==> Fulano De Tal;
 *    "fulano de tal" ==> Fulano De Tal;
 *
 * @param str texto a ser tratado
 *
 */
export function getTitleCase(str: string) {
  const texto = str.toLowerCase().split(' ');
  for (let i = 0; i < texto.length; i++) {
    texto[i] = texto[i][0].toUpperCase() + texto[i].slice(1);
  }
  return texto.join(' ');
}

/**
 * Converte objetos Date em String.
 * Formatos: dd/MM/yyyy
 * Referência: https://angular.io/api/common/DatePipe
 *
 * @param date objeto Date
 * @param format valor 'dd/MM/yyyy'
 */
export function convertDateToStringFormat(date: Date, format: string): string {
  return new DatePipe('pt-BR').transform(new Date(date), format);
}

/**
 * Realiza o sort para organizar os itens.
 * @param a atividade criterio
 * @param b atividade criterio
 */
export function sort(a: AtividadeCriterio, b: AtividadeCriterio): number {
  // eslint-disable-next-line curly
  if (a.pactuada === a.entregue) return 1;
  // eslint-disable-next-line curly
  if (b.pactuada === b.entregue) return 1;
  // eslint-disable-next-line curly
  if (a.pactuada !== a.entregue) return -1;
  // eslint-disable-next-line curly
  if (b.pactuada !== b.entregue) return -1;
}
