<form [formGroup]="form" class="container">

    <input type="hidden" formControlName="id" />
  
    <mat-form-field appearance="outline">
      <mat-label for="orgao" attr.aria-hidden="true">Órgão</mat-label>
      <input
        matInput
        name="orgao"
        aria-label="Orgão"
        [value]="form.controls['orgao']?.value?.descricao"
        disabled
      />
    </mat-form-field>
   
    <mat-form-field appearance="outline">
      <mat-label for="nomeCargo" attr.aria-hidden="true">Cargo</mat-label>
      <input
        matInput
        name="nomeCargo"
        formControlName="nome"
        aria-label="Cargo"
        [value]="form.controls['nome']?.value"
        required
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label for="nivel" attr.aria-hidden="true">Nível</mat-label>
      <input
        matInput
        name="nivel"
        formControlName="nivel"
        aria-label="Nível"
        [value]="form.controls['nivel']?.value"
      />
    </mat-form-field>
    
  </form>
  