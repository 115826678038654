<form [formGroup]="form">
  <input type="hidden" formControlName="id" />

  <mat-label *ngIf="!readonly">
    Metodologia
  </mat-label>
  <dft-multiselect
    formControlName="tipo"
    placeholder="Selecione a metodologia"
    [desabilitado]="readonly"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="metodologias">
  </dft-multiselect>

  <br />

  <mat-form-field appearance="outline">
    <mat-label>Categoria de serviço</mat-label>
    <input
      aria-label="Nome da categoria de serviço"
      matInput
      name="descricao"
      placeholder="Ex.: Administrativo Corporativo"
      formControlName="descricao"
      [readonly]="readonly"
      required
    />
  </mat-form-field>

  <br />

  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">Código da entrega</mat-label>
    <input aria-label="Código da base da entrega com 8 dígitos"
      matInput
      name="codBaseEntrega"
      mask="0000.0000"
      formControlName="codBaseEntrega"
    />
  </mat-form-field>
  <p class="text-danger" *ngIf="$codigoBaseEntregaAlterado | async">
    A alteração do código base da categoria implica na
    <br/>atualização da numeração das entregas relacionadas.
  </p>

</form>
