import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MenuMode } from './menu-mode';

@Injectable()
export class MenuService {
  descendantMenuItemClick$ = new Subject<any>();
  childMenuItemClick$ = new Subject<any>();
  mode$ = new BehaviorSubject<MenuMode>('vertical');
  inlineIndent$ = new BehaviorSubject<number>(24);
  isChildSubMenuOpen$ = new BehaviorSubject<boolean>(false);
  subMenus = 0;
  menuId = 0;

  onDescendantMenuItemClick(menu: any): void {
    this.descendantMenuItemClick$.next(menu);
  }

  onChildMenuItemClick(menu: any): void {
    this.childMenuItemClick$.next(menu);
  }

  setMode(mode: MenuMode): void {
    this.mode$.next(mode);
  }

  setInlineIndent(indent: number): void {
    this.inlineIndent$.next(indent);
  }
  setMenuId(id: number): void {
    this.menuId = id;
  }
  getNewSubmenuId(): number {
    return ++this.subMenus;
  }
}
