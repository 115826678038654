<form [formGroup]="form">

  <input type="hidden" formControlName="id" />

  <legend>Identificação</legend>

  <mat-label attr.aria-hidden="true">Órgão</mat-label>
  <dft-multiselect
    formControlName="orgao"
    placeholder="Selecione o órgão"
    campoDeTexto="bindName"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="listaDeOrgaos"
    ordenacao="descricao"
    (ngModelChange)="selecionarOrgao($event)">
  </dft-multiselect>

  <br />

  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">CPF</mat-label>
    <input aria-label="CPF"
      matInput
      name="cpf"
      mask="000.000.000-00"
      placeholder="Ex.: 123.456.789-87"
      formControlName="cpf"
      required
    />

    <button
      mat-button
      matSuffix
      mat-icon-button
      [disabled]="!form.controls.orgao?.valid || !form.controls.cpf?.valid"
      (click)="buscarDadosPeloCPF(form.controls.cpf.value)"
      matTooltip="Buscar Dados do Usuário"
      aria-label="Buscar dados do usuário pelo CPF digitado"
    >
      <mat-icon attr.aria-hidden="true">search</mat-icon>
    </button>
    <mat-error *ngIf="form.controls.cpf?.invalid" role="alert">O CPF digitado é inválido</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">Nome</mat-label>
    <input #inputNome aria-label="Nome"
      matInput
      name="nome"
      formControlName="nome"
      (input)="nome.setValue($event.target.value.toUpperCase())"
      placeholder="Ex.: Fulano Beltrano"
      required
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">Data de Nascimento</mat-label>
    <input aria-label="Data de nascimento"
      matInput
      placeholder="Ex.: 25/06/1988"
      name="dataNascimento"
      [matDatepicker]="dtNascimento"
      formControlName="dataNascimento"
      required
    />
    <mat-datepicker-toggle matSuffix [for]="dtNascimento"></mat-datepicker-toggle>
    <mat-datepicker #dtNascimento startView="multi-year"> </mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">Sexo</mat-label>
    <mat-select aria-label="Sexo" name="sexo" formControlName="sexo" placeholder="Selecione o sexo">
      <mat-option>Não especificado</mat-option>
      <mat-option value="F">Feminino</mat-option>
      <mat-option value="M">Masculino</mat-option>
    </mat-select>
  </mat-form-field>

  <legend>Dados Pessoais</legend>

  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">Ramal / Telefone / Celular</mat-label>
    <input aria-label="Número do ramal, telefone fixo ou celular"
      #telefone
      id="campoTelefoneFormulario"
      matInput
      name="telefone"
      [mask]="mask"
      (input)="adaptaMascaraDeTelefone(telefone)"
      placeholder="Ex.: (99) 99999-9999"
      formControlName="telefone"
    />
  </mat-form-field>

  <br />

  <mat-label attr.aria-hidden="true">Escolaridade</mat-label>
  <dft-multiselect
    formControlName="escolaridade"
    placeholder="Selecione a escolaridade"
    campoDeTexto="nome"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="escolaridades">
  </dft-multiselect>

  
</form>
