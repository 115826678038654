import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RelatorioDescricaoUnidade } from '@dft/modules/relatorios/relatorio-descricao-unidade/relatorio-descricao-unidade';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StatusDimensionamentoEnum } from '../enums/status-dimensionamento.enum';
import { CalculoTodos } from '../models/calculo-todos';
import { Dimensionamento } from '../models/dimensionamento';
import { DimensionamentoEntrega } from '../models/dimensionamento-entrega';
import { DimensionamentoEsforco } from '../models/dimensionamento-esforco';
import { DimensionamentoHistorico } from '../models/dimensionamento-historico';
import { DimensionamentoIndicador } from '../models/dimensionamento-indicador';
import { DimensionamentoPessoa } from '../models/dimensionamento-pessoa';
import { DimensionamentoSimples } from '../models/dimensionamento-simples';
import { DimensionamentoValidacao } from '../models/dimensionamento-validacao';
import { IndicadorDashboard } from '../models/indicador-dashboard';
import { IndicadorProdutividade } from '../models/indicador-produtividade';
import { IndicadorProdutividadeDimensionamento } from '../models/indicador-produtividade-dimensionamento';
import { IndiceTempoProdutivo } from '../models/indice-tempo-produtivo';
import { PessoaAfastamento } from '../models/pessoa-afastamento';
import { QuantitativoDePessoas } from '../models/quantitativo-pessoas';
import { RankingEntregaEsforco } from '../models/ranking-entrega-esforco';
import { RankingEsforcoCategoriaServico } from '../models/ranking-esforco-categoria-servico';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class DimensionamentoService {
  private readonly apiOrgaos = `${environment.urlApi}/orgaos`;
  private readonly apiDimensionamentos = `${environment.urlApi}/dimensionamentos`;
  private readonly apiPessoas = `${environment.urlApi}/pessoas`;

  constructor(private http: HttpClient) { }

  public consolidar(dimensionamentos: number[]) {
    return this.http.post<Dimensionamento[]>(
      `${this.apiDimensionamentos}/consolidacoes`,
      dimensionamentos,
      httpOptions
    );
  }

  public recuperarDimensionamento(id: number): Observable<Dimensionamento> {
    return this.http.get<Dimensionamento>(`${this.apiDimensionamentos}/${id}`);
  }

  public listarDimensionamentos(
    somenteConsolidados?: boolean
  ): Observable<DimensionamentoSimples[]> {
    return this.http.get<DimensionamentoSimples[]>(
      somenteConsolidados
        ? this.apiDimensionamentos + '?consolidado=true'
        : this.apiDimensionamentos,
      httpOptions
    );
  }

  public listarDimensionamentosDoOrgao(
    idOrgao: number,
    status: StatusDimensionamentoEnum
  ): Observable<Dimensionamento[]> {
    return this.http.get<Dimensionamento[]>(
      status
        ? `${this.apiOrgaos}/${idOrgao}/dimensionamentos?status=${status}`
        : `${this.apiOrgaos}/${idOrgao}/dimensionamentos`,
      httpOptions
    );
  }

  public listarDimensionamentosDaPessoa(
    idPessoa: number
  ): Observable<DimensionamentoPessoa[]> {
    return this.http.get<DimensionamentoPessoa[]>(
      `${this.apiPessoas}/${idPessoa}/dimensionamentos`,
      httpOptions
    );
  }

  public listarDimensionamentosDaPessoaFuncional(
    idPessoa: number,
    idPessoaFuncional: number
  ): Observable<DimensionamentoPessoa[]> {
    return this.http.get<DimensionamentoPessoa[]>(
      `${this.apiPessoas}/${idPessoa}/dados-funcionais/${idPessoaFuncional}/dimensionamentos`,
      httpOptions
    );
  }

  /**
   * Retorna as entregas de um dimensionamento
   * @param idDimensionamento id do dimensionamento
   * @param semTipoOutra se deseja trazer apenas as entregas de categoria de serviço do tipo
   * diferente de OUTRA (Ex: Gestão)
   */
  public listarEntregasDoDimensionamento(
    idDimensionamento: number,
    semTipoOutra: boolean
  ) {
    return this.http.get<DimensionamentoEntrega[]>(
      `${this.apiDimensionamentos}/${idDimensionamento}/entregas?semTipoOutra=${semTipoOutra}`,
      httpOptions
    );
  }

  public atualizarEntregasDoDimensionamento(
    idDimensionamento: number,
    listaDimEntregas: DimensionamentoEntrega[]
  ) {
    return this.http.post<DimensionamentoEntrega[]>(
      `${this.apiDimensionamentos}/${idDimensionamento}/entregas`,
      listaDimEntregas,
      httpOptions
    );
  }

  public listarEsforcosDoDimensionamento(idDimensionamento: number) {
    return this.http.get<DimensionamentoEsforco[]>(
      `${this.apiDimensionamentos}/${idDimensionamento}/esforcos`,
      httpOptions
    );
  }

  /**
   * Retorna os afastamentos do dimensionamento
   * @param idDimensionamento id do dimensionamento
   * @param apenasDoCalculo retorna apenas os afastamentos usados no calculo
   * @returns os afastamentos do dimensionamento
   */
  public listarAfastamentosDoDimensionamento(idDimensionamento: number, apenasDoCalculo: boolean) {
    return this.http.get<PessoaAfastamento[]>(
      `${this.apiDimensionamentos}/${idDimensionamento}/afastamentos?apenasDoCalculo=${apenasDoCalculo}`,
      httpOptions
    );
  }

  public listarEsforcosDaPessoaFuncionalNoDimensionamento(
    idDimensionamento: number,
    idPessoaFuncional: number
  ) {
    return this.http.get<DimensionamentoEsforco[]>(
      `${this.apiDimensionamentos}/${idDimensionamento}/esforcos?idPessoaFuncional=${idPessoaFuncional}`,
      httpOptions
    );
  }

  public listarIndicadoresDoDimensionamento(idDimensionamento: number) {
    return this.http.get<DimensionamentoIndicador[]>(
      `${this.apiDimensionamentos}/${idDimensionamento}/indicadores`,
      httpOptions
    );
  }

  public listarIndicadoresDimensionamentoAtipico(idDimensionamento: number) {
    return this.http.get<IndicadorDashboard>(
      `${this.apiDimensionamentos}/${idDimensionamento}/indicadores-atipico`,
      httpOptions
    );
  }

  public listarRankingDeEsforcoPorEntrega(idDimensionamento: number) {
    return this.http.get<RankingEntregaEsforco[]>(
      `${this.apiDimensionamentos}/${idDimensionamento}/ranking-entrega`,
      httpOptions
    );
  }

  public listarRankingDeEsforcoPorCategoriaServico(idDimensionamento: number) {
    return this.http.get<RankingEsforcoCategoriaServico[]>(
      `${this.apiDimensionamentos}/${idDimensionamento}/ranking-categoriaservico`,
      httpOptions
    );
  }

  /**
   * Recupera todos dos resultados obtidos em um cálculo de
   * um dimensionamento atípico.
   *
   * @param id Número identificador do dimensionamento.
   *
   * Exemplo de API: `/api/dimensionamentos/1/calculos-atipicos`
   */
  public listarCalculosAtipicosDoDimensionamento(id: number) {
    return this.http.get<QuantitativoDePessoas[]>(
      `${this.apiDimensionamentos}/${id}/calculos-atipicos`,
      httpOptions
    );
  }

  /**
   * Remove o cálculo atípico de um dimensionamento.
   *
   * @param idDimensionamento Número identificador do dimensionamento.
   *
   * Exemplo de API: `/api/dimensionamentos/1/calculos-atipicos`
   */
  public removerCalculoAtipicoDoDimensionamento(idDimensionamento: number) {
    return this.http.delete(
      `${this.apiDimensionamentos}/${idDimensionamento}/calculos-atipicos`,
      httpOptions
    );
  }

  /**
   * Recupera todos dos indicadores de produtividade selecionados
   * para um dimensionamento.
   *
   * @param id Número identificador do dimensionamento.
   *
   * Exemplo de API: `/api/dimensionamentos/1/produtividades`
   */
  public listarProdutividadesDoDimensionamento(id: number) {
    return this.http.get<IndicadorProdutividadeDimensionamento[]>(
      `${this.apiDimensionamentos}/${id}/produtividades`,
      httpOptions
    );
  }

  /**
   * Remove a associação de um indicador de produtividade com
   * um dimensionamento.
   *
   * @param idDimensionamento Número identificador do dimensionamento.
   * @param idProdutividade Número identificador do indicador de produtividade.
   *
   * Exemplo de API: `/api/dimensionamentos/1/produtividades/1`
   */
  public removerProdutividadeDoDimensionamento(
    idDimensionamento: number,
    idProdutividade
  ) {
    return this.http.delete<IndicadorProdutividade>(
      `${this.apiDimensionamentos}/${idDimensionamento}/produtividades/${idProdutividade}`,
      httpOptions
    );
  }

  /**
   * Associa uma lista de indicadores de produtividade a
   * um dimensionamento.
   *
   * @param id Número identificador do dimensionamento.
   * @param produtividades Array de objetos JSON contendo identificadores de indicadores de produtividade.
   *
   * Exemplo de API: `/api/dimensionamentos/1/produtividades`
   *
   * [
   *  {id: 2},
   *  {id: 3}
   * ]
   */
  public selecionarProdutividadesDoDimensionamento(
    id: number,
    produtividades: Array<any>
  ) {
    return this.http.post<Array<any>>(
      `${this.apiDimensionamentos}/${id}/produtividades`,
      produtividades,
      httpOptions
    );
  }

  public inserirDimensionamento(dimensionamento: Dimensionamento) {
    return this.http.post<Dimensionamento>(
      `${this.apiDimensionamentos}`,
      dimensionamento,
      httpOptions
    );
  }

  public deletarDimensionamento(idDimensionamento: number) {
    return this.http.delete<Dimensionamento>(
      `${this.apiDimensionamentos}/${idDimensionamento}`,
      httpOptions
    );
  }

  public editarDimensionamento(dimensionamento: DimensionamentoSimples): any {
    if (!dimensionamento.descricao) {
      dimensionamento.descricao = null;
    }

    return this.http.put<DimensionamentoSimples>(
      `${this.apiDimensionamentos}/${dimensionamento.id}`,
      dimensionamento,
      httpOptions
    );
  }

  public alterarEstadoDoDimensionamento(
    { id }: DimensionamentoSimples,
    status: StatusDimensionamentoEnum,
    descricaoDevolucao: string = null
  ) {
    const body = {
      status: StatusDimensionamentoEnum[status],
      descricaoStatus: descricaoDevolucao
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.patch(`${this.apiDimensionamentos}/${id}/status`, body, { headers });
  }

  public listarHistoricoDoDimensionamento(idDimensionamento: number) {
    return this.http.get<DimensionamentoHistorico[]>(
      `${this.apiDimensionamentos}/${idDimensionamento}/historicos`
    );
  }

  public listarValidacoesDoDimensionamento(idDimensionamento: number) {
    return this.http.get<DimensionamentoValidacao[]>(
      `${this.apiDimensionamentos}/${idDimensionamento}/validacoes`
    );
  }

  public editarDimensionamentoPessoa(dimensionamento: Dimensionamento) {
    return this.http.post<DimensionamentoPessoa>(
      `${this.apiDimensionamentos}/${dimensionamento.id}/pessoas`,
      dimensionamento,
      httpOptions
    );
  }

  public gerarCalculosDimensionamento(
    idDimensionamento: number,
    indiceTempoProdutivo: IndiceTempoProdutivo
  ) {
    return this.http.post<IndiceTempoProdutivo>(
      `${this.apiDimensionamentos}/${idDimensionamento}/controles/indice-tempo-produtivo`,
      indiceTempoProdutivo,
      httpOptions
    );
  }

  public gerarTodosCalculosDimensionamento(
    dimensionamentos: DimensionamentoSimples[],
    indiceTempoProdutivo: IndiceTempoProdutivo
  ): Promise<any> {
    const calculoTodos = new CalculoTodos();
    calculoTodos.idsDimensionamentos = [];

    dimensionamentos.forEach((dimensionamento) => {
      calculoTodos.idsDimensionamentos.push(dimensionamento.id);
    });

    calculoTodos.valor = indiceTempoProdutivo.valor;

    return this.http.post<CalculoTodos>(
      `${this.apiDimensionamentos}/calcula-todos-dimensionamentos`,
      calculoTodos,
      httpOptions
    ).toPromise();
  }

  public isDimensionamentoEditavel(dimensionamento: Dimensionamento) {
    return (
      dimensionamento.status === StatusDimensionamentoEnum.DEVOLVIDO_AJUSTES ||
      dimensionamento.status === StatusDimensionamentoEnum.EM_ELABORACAO
    );
  }

  montarDescricaoLabel(dimensionamento: Dimensionamento): string {
    const texto = 'Dimensionamento';
    if (!dimensionamento.descricao) {
      return `${texto} sem observação. Nome: ${dimensionamento.nome}.`;
    }
    return `${texto} com observação: ${dimensionamento.descricao}.`;
  }

  notificarUsuarios(dimensionamento: Dimensionamento): Observable<any> {
    return this.http.post<any>(
      `${this.apiDimensionamentos}/${dimensionamento.id}/notificar`,
      httpOptions
    );
  }

  /**
   * Tela de Relatório de Descrição da Unidade:
   * Recupera relatório de descrição de determinada unidade por dimensionamento.
   *
   * @param idDimensionamento Número identificador do dimensionamento.
   *
   * Exemplo de API: `/api/dimensionamentos/1/relatorio-descricao-unidade`
   */
  public gerarRelatorioDescricaoUnidadePorDimensionamento(dimensionamento: Dimensionamento): Observable<RelatorioDescricaoUnidade[]> {
    return this.http.get<RelatorioDescricaoUnidade[]>(
      `${this.apiDimensionamentos}/${dimensionamento.id}/relatorio-descricao-unidade`,
      httpOptions
    );
  }
}
