import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


/** Inject With Credentials into the request */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  private readonly ERRO_TOKEN_EXPIRADO = 'DIMENSIONAMENTO-007';

  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response: HttpErrorResponse) => {
        const error = response.error;
        if (error.codigoErro === this.ERRO_TOKEN_EXPIRADO) {
          this.router.navigate(['/error/token-expired']);
        }
        return throwError(error);
      })
    );
  }
}
