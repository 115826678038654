import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TipoMetodologiaEnum } from '../enums/metodologia.enum';
import { CategoriaServico } from '../models/categoria-servico';

const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

@Injectable({
  providedIn: 'root'
})
export class CategoriaServicoService {

  private readonly api = `${environment.urlApi}/categorias-servico`;

  constructor(private http: HttpClient) { }

  public listarCategorias(tipoMetodologia?: TipoMetodologiaEnum) {
    let params = new HttpParams();
    if (tipoMetodologia) {
      params = params.append('tipoMetodologia', tipoMetodologia);
    }

    return this.http.get<CategoriaServico[]>(`${this.api}`, { headers, params });
  }

  public inserirCategoria(categoria: CategoriaServico) {
    return this.http.post<CategoriaServico>(`${this.api}`, categoria, { headers });
  }

  public editarCategoria(categoria: CategoriaServico) {
    return this.http.put<CategoriaServico>(`${this.api}/${categoria.id}`, categoria, { headers });
  }

  public deletarCategoria(categoria: CategoriaServico) {
    return this.http.delete<CategoriaServico>(`${this.api}/${categoria.id}`, { headers });
  }

  /**
   * Busca sugestão de código de entrega de acordo com a categoria de serviço.
   * @param idCategoria id da categoria de serviço.
   */
  public buscarSugestaoCodigoEntregaPorCategoria(idCategoria: number) {
    return this.http.get<string[]>(
      `${this.api}/${idCategoria.toString()}/entregas/sugestao-codigo`
    );
  }

  /**
   * Busca sugestão de código de base da categoria de entrega..
   */
  public buscarSugestaoCodigoBaseCategoriaEntrega(): Observable<string> {
    return this.http.get(`${this.api}/sugestao-codigo`, { responseType: 'text' });
  }

}
