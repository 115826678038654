import { TipoAutenticador } from '@dft/shared/enums/tipo-autenticador.enum';

const BACKEND_URL = '/dimensionamento_backend';

export const environment = {
  production: false,
  autenticacao: {
    tipo: TipoAutenticador.LOCAL,
    loginTexto: true,
    url: '',
    realm: '',
    clientId: '',
    clientSecret: '',
  },
  urlApi: `${BACKEND_URL}/api`,
  redirectUrl: `${BACKEND_URL}/callback`,
  version: '1.20.0-ana-3',
};
