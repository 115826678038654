<mat-card id="pessoas-container" class="mat-elevation-z2">
  <mat-card-content>
    <mat-form-field>
      <input
        matInput
        aria-label="Filtrar pessoas por nome ou CPF"
        placeholder="Filtrar pessoas por nome ou CPF"
        (keyup)="filtrar($event.target.value)"
      />
    </mat-form-field>

    <form [formGroup]="form">
      <table
        mat-table
        [dataSource]="datasource"
        matSort
        matSortActive="nome"
        matSortDisableClear
        matSortDirection="asc">
        
        <ng-container matColumnDef="pessoa">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="nome">
            PESSOA
          </th>
          <td mat-cell *matCellDef="let element" [formGroup]="element" role="text"
            attr.aria-label="Marque para selecionar {{element.get('pessoa').value.nome}} para o Dimensionamento">
            <mat-checkbox
              formControlName="selecionado"
              class="wrap-mat-checkbox"
            >
              {{ element.get('pessoa').value.nome }}
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="matricula">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="matricula">
            MATRÍCULA
          </th>
          <td mat-cell *matCellDef="let element" [formGroup]="element" role="text"
            attr.aria-label="Marque para selecionar {{element.get('pessoaFuncional').value.matricula}} para o Dimensionamento">
              {{ element.get('pessoaFuncional').value.matricula }}
          </td>
        </ng-container>

        <ng-container matColumnDef="cargaHoraria">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="cargaHoraria">
            CARGA HORÁRIA
          </th>
          <td mat-cell *matCellDef="let element" [formGroup]="element" role="text"
            attr.aria-label="Marque para selecionar {{element.get('pessoaFuncional').value.cargaHoraria}} para o Dimensionamento">
              {{ element.get('pessoaFuncional').value.cargaHoraria }}
          </td>
        </ng-container>

        <ng-container matColumnDef="funcao">
          <th mat-header-cell *matHeaderCellDef>
            ATIVIDADE
          </th>
          <td mat-cell *matCellDef="let element" [formGroup]="element"
            attr.aria-label="Marque esta opção para definir atribuição de atividade para '{{element.get('pessoa').value.nome}}'">
            <mat-radio-group formControlName="funcao" class="funcao">
              <mat-radio-button *ngIf="habilitaAtividadeApoio && dimensionamento?.tipo === 'TIPICA'" [value]="'APOIO'">
                Apoio Exclusivo
              </mat-radio-button>
              <mat-radio-button [value]="'GESTOR'">Gestor</mat-radio-button>
              <mat-radio-button *ngIf="habilitaAtividadeApoio && dimensionamento?.tipo === 'TIPICA'" [value]="null">
                Executor das Entregas
              </mat-radio-button>
              <mat-radio-button *ngIf="habilitaAtividadeApoio && dimensionamento?.tipo === 'ATIPICA'" [value]="null">
                Executor
              </mat-radio-button>
            </mat-radio-group>  
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="colunasExibidas"></tr>
        <tr mat-row *matRowDef="let row; columns: colunasExibidas"></tr>
      </table>
    </form>
    <mat-paginator
      [length]="tamanhoDosResultados"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
    ></mat-paginator>
  </mat-card-content>

</mat-card>
<br />