<form [formGroup]="form" class="container">
  <input type="hidden" formControlName="id" />

  <mat-label attr.aria-hidden="true"> Tipo de Poder </mat-label>
  <dft-multiselect appearance="outline"
    formControlName="tipoPoder"
    placeholder="Selecione o tipo de poder."
    [desabilitado]="readonly"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="poderes"
    campoDeTexto="descricao"
    permitirFiltroDePesquisa="false"
    #tipoPoder>
  </dft-multiselect>

  <br />

  <mat-label attr.aria-hidden="true"> Tipo de Órgão </mat-label>
  <dft-multiselect appearance="outline"
    formControlName="tipoOrgao"
    placeholder="Selecione o tipo de Órgão."
    [desabilitado]="readonly"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="orgaos"
    campoDeTexto="descricao"
    permitirFiltroDePesquisa="false"
    #tipoOrgao>
  </dft-multiselect>

  <br />

  <mat-form-field appearance="outline">
    <mat-label>Nome do Órgão</mat-label>
    <input
      aria-label="Nome do órgão"
      matInput
      name="descricao"
      placeholder="Ex.: Ministério da Economia"
      formControlName="descricao"
      [readonly]="readonly"
      required
    />
  </mat-form-field>

  <mat-grid-list cols="2" rowHeight="5:2">
    <mat-grid-tile>
      <mat-form-field appearance="outline">
        <mat-label>Sigla do Órgão</mat-label>
        <input
          aria-label="Sigla do órgão"
          matInput
          name="sigla"
          placeholder="Ex.: TSE, STF ou ME"
          formControlName="sigla"
          maxlength="10"
          [readonly]="readonly"
          required
        />
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field appearance="outline">
        <mat-label>Código do Órgão</mat-label>
        <input
          aria-label="Código do órgão"
          matInput
          name="codigoReferencia"
          formControlName="codigoReferencia"
          placeholder="Ex.: 12345"
          maxlength="15"
          [readonly]="readonly"
        />
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-label attr.aria-hidden="true">
    Categorias de Serviços Típicas
  </mat-label>
  <dft-multiselect *ngIf="!readonly"
    formControlName="metodologiasTipicas"
    placeholder="Selecione as categorias de serviços típicas"
    [desabilitado]="readonly"
    [delineado]="true"
    [selecaoUnica]="false"
    [listaDeOpcoes]="metodologiasTipicas"
    ordenacao="descricao"
    [permitirFiltroDePesquisa]=true>
  </dft-multiselect>

  <br />

  <mat-label attr.aria-hidden="true">
    Categorias de Serviços Atípicas
  </mat-label>
  <dft-multiselect *ngIf="!readonly"
    formControlName="metodologiasAtipicas"
    placeholder="Selecione as categorias de serviços atípicas"
    [delineado]="true"
    [selecaoUnica]="false"
    [listaDeOpcoes]="metodologiasAtipicas"
    ordenacao="descricao"
    [permitirFiltroDePesquisa]=true>
  </dft-multiselect>

  <br/>

  <ng-container *ngIf="!esconderListaOrgaos">
    <mat-label attr.aria-hidden="true">
      Clonar Afastamentos
    </mat-label>
    <dft-multiselect *ngIf="!readonly"
      aria-label="Selecione Orgão para clonar afastamentos"
      formControlName="orgaoClone"
      placeholder="Selecione Orgão para clonar afastamentos"
      [delineado]="true"
      [selecaoUnica]="true"
      [listaDeOpcoes]="listaDeOrgaos"
      ordenacao="descricao"
      [permitirFiltroDePesquisa]=true>
  </dft-multiselect>
  </ng-container>

  <br />

  <mat-label attr.aria-hidden="true">
    Disponibilizar cálculo e dashboards para Gestor de Órgão?
  </mat-label>
  <mat-slide-toggle
    aria-label="Gestor de Órgão pode gerar cálculos e visualizar dashboards?"
    class="margem-slider"
    formControlName="visibilidadeGestorOrgao">
  </mat-slide-toggle>

</form>
