import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TipoMetodologiaEnum } from '../enums/metodologia.enum';
import { Entrega } from '../models/entrega';
import { Formacao } from '../models/formacao';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EntregaService {

  private readonly apiEntregas = `${environment.urlApi}/entregas`;

  constructor(private http: HttpClient) {}

  public listarEntregas(metodologia?: TipoMetodologiaEnum): Observable<Entrega[]> {
    const entregas = this.http.get<Entrega[]>(`${this.apiEntregas}`, httpOptions);
    if (!metodologia) {
      return entregas;
    }
    return entregas.pipe(
      map(es => es.filter(e => e.categoriaServico.tipo === metodologia))
    );
  }

  public buscarEntregaId(idEntrega: number) {
    return this.http.get<Entrega>(`${this.apiEntregas}/` + idEntrega, httpOptions);
  }

  public buscarCodigoEntrega(cdEntrega: string) {
    return this.http.get<Entrega>(`${this.apiEntregas}/porCodigoEntrega/` + cdEntrega, httpOptions);
  }

  public listarFormacoes(entrega: Entrega): Observable<Formacao[]> {
    return this.http.get<Formacao[]>(`${this.apiEntregas}/${entrega.id}/formacoes`, httpOptions);
  }

  public inserirEntrega(entrega: Entrega) {
    return this.http.post<Entrega>(`${this.apiEntregas}`, entrega, httpOptions);
  }

  public deletarEntrega(entrega: Entrega) {
    return this.http.delete<Entrega>(
      `${this.apiEntregas}/${entrega.id}`,
      httpOptions
    );
  }

  public editarEntrega(entrega: Entrega) {
    return this.http.put<Entrega>(
      `${this.apiEntregas}/${entrega.id}`,
      entrega,
      httpOptions
    );
  }

  public gerarPlanilha(id?: number) {
    return this.http.get<any>(`${this.apiEntregas}/planilha`, {
      ...httpOptions,
      params: id && ({ idOrgao: id })
    });
  }

  public verificaEntregaTemVinculo(entrega: Entrega) {
    return this.http.get<boolean>(`${this.apiEntregas}/${entrega.id}/vinculo`, httpOptions);
  }
}
