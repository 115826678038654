<form [formGroup]="form" class="container">

  <input type="hidden" formControlName="id" />

  <mat-form-field appearance="outline">
    <mat-label for="orgao" attr.aria-hidden="true">Órgão</mat-label>
    <input
      matInput
      name="orgao"
      aria-label="Orgão"
      [value]="form.controls['orgao']?.value?.descricao"
      disabled
    />
  </mat-form-field>
 
  <mat-form-field appearance="outline">
    <mat-label for="nome" attr.aria-hidden="true">Escolaridade</mat-label>
    <input
      matInput
      name="nome"
      formControlName="nome"
      aria-label="Escolaridade"
      [value]="form.controls['nome']?.value"
      required
    />
  </mat-form-field>
  
</form>
