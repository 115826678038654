<form [formGroup]="form" class="container">

  <div [hidden]="isCalculando || isCalculoFinalizado ">
    <mat-form-field appearance="outline" [hidden]="isCalculando">
      <mat-label>Índice de Tempo Produtivo (%)</mat-label>
      <input
        matInput
        name="indice"
        type="number"
        step="0.1"
        min="0"
        max="100"
        placeholder="Ex.: 37.65"
        formControlName="indice"
      />
      <mat-hint>Insira o valor em porcentagem.</mat-hint>
    </mat-form-field>

    <br/>

    <div align="end" >
      <button 
          mat-stroked-button color="primary" 
          matTooltip="Calcular"
          aria-label="Calcular"
          (click)="calculaTodosDimensionamentos()"
          (keypress)="calculaTodosDimensionamentos()">
        <mat-icon class="material-icons-outlined" >play_for_work</mat-icon>
        Calcular os {{totalDimensionamentosParaCalculo}} Dimensionamentos
      </button>
    </div><br/>

    <table
      mat-table
      [dataSource]="dataSource"
      class="table table-bordered table-hover largura"
      matSort
      matSortActive="nome"
      matSortDisableClear
      matSortDirection="asc"
      aria-label="Tabela contendo a lista de dimensionamentos que serão calculados"
    >

      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="nome">
          DIMENSIONAMENTOS PARA CÁLCULO
        </th>
        <td mat-cell *matCellDef="let dimensionamento">
          <div role="text"
            matTooltip="{{dimensionamento.descricao}} - {{dimensionamento.tipo}}"
            attr.aria-label="{{ montarDescricaoLabel(dimensionamento) }}; 
              de metotologia {{dimensionamento.tipo}}; 
              de status {{statusDimensionamentoMap.get(dimensionamento.status)}}"
            matBadge="{{dimensionamento.tipo[0]}}"
            matBadgePosition="before"
            matBadgeColor="{{
              dimensionamento.tipo === TipoMetodologiaEnum.ATIPICA
                ? 'accent'
                : 'primary'
            }}"
            matBadgeOverlap="false"
          >
            <span>{{ dimensionamento.nome }} </span>
            <span [ngClass]="{badge: true,
              em_elaboracao:
                dimensionamento.status ===
                    StatusDimensionamentoEnum.EM_ELABORACAO,
              analisado:
                dimensionamento.status === StatusDimensionamentoEnum.EM_ANALISE ||
                dimensionamento.status === StatusDimensionamentoEnum.ANALISADO,
              devolvido:
                dimensionamento.status ===
                  StatusDimensionamentoEnum.DEVOLVIDO_AJUSTES,
              dimensionado:
                dimensionamento.status === StatusDimensionamentoEnum.DIMENSIONADO
              }"> {{ (dimensionamento.status | statusDimensionamento) | uppercase }}</span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div>
      <mat-paginator
        [length]="resultsLength"
        [pageSize]="4"
        [pageSizeOptions]="[4, 5, 10, 25, 50, 100]"
      >
      </mat-paginator>
    </div>
  </div>

  <div align="middle">

    <div [hidden]="!isCalculando">
      Processando {{totalJaProcessado | async}} de {{totalDimensionamentosParaCalculo}} Dimensionamentos
      <br><br>
      <mat-progress-bar *ngIf="isCalculando" mode="determinate" [value]="porcentagemJaProcessado | async"></mat-progress-bar>
      <br>
      <strong>Aguarde o término do processamento, não feche esta janela.</strong>
    </div>

    <div [hidden]="!isCalculoFinalizado">
      {{totalJaProcessado | async}} Dimensionamentos foram calculados!
    </div>

  </div>
</form>
