export enum MetricaEntregaEnum {
  QUANTITATIVO = 'QUANTITATIVO',
  QUALITATIVO = 'QUALITATIVO'
}

export const METRICA_ENTREGA_MAP: Array<{ id: string, descricao: string }> = [
  { id: MetricaEntregaEnum.QUANTITATIVO, descricao: 'Quantitativo' },
  { id: MetricaEntregaEnum.QUALITATIVO, descricao: 'Qualitativo' },
];

