<form [formGroup]="form">

  <mat-form-field appearance="outline">
    <mat-label>Dimensionamento</mat-label>
    <input arial-label="Dimensionamento"
      matInput
      name="dimensionamento"
      [value]="dimensionamento.nome"
      readonly=true
      disabled=true
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Nome do responsável</mat-label>
    <input
      matInput
      name="responsavel"
      formControlName="responsavel"
      readonly=true
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>CPF do responsável</mat-label>
    <input
      matInput
      name="cpf"
      mask="000.000.000-00"
      formControlName="cpf"
      readonly=true
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Descrição dos Ajustes</mat-label>
    <textarea aria-label="Descrição dos Ajustes"
      matInput
      name="descricao"
      rows="6"
      formControlName="descricao"
      required
      #descricao
      (ngModelChange)="form.controls['descricao'].markAsTouched()"
    ></textarea>
    <mat-error *ngIf="form.controls['descricao'].invalid">{{obtemMensagemDeErro()}}</mat-error>
  </mat-form-field>  
</form>
