import { PessoaFuncional } from './dado-funcional';
import { Escolaridade } from './escolaridade';
import { Orgao } from './orgao';

export class Pessoa {

  public orgaoId?: number;
  public orgaoNome?: string;

  public id: number;
  public cpf: string;
  public nome: string;
  public telefone: string;
  public orgao: Orgao;
  public dataNascimento: string;
  public nomeCpf: string;
  public sexo: string;
  public pessoaDadosFuncionais: PessoaFuncional[];
  public escolaridade: Escolaridade;

  constructor(
    id?: number,
    cpf?: string,
    nome?: string,
    telefone?: string,
    orgao?: Orgao,
    sexo?: string,
    escolaridade?: Escolaridade,
  ) {
    this.id = id;
    this.cpf = cpf;
    this.nome = nome;
    this.telefone = telefone;
    this.orgao = orgao;
    this.sexo = sexo;
    this.escolaridade = escolaridade;
  }
}
