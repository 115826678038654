<form [formGroup]="form" class="container-form">
  <br/>
  <mat-label attr.aria-hidden="true">
    Formação
  </mat-label>
  <dft-multiselect
    formControlName="formacao"
    placeholder="Selecione o nível de formação da Entrega"
    [desabilitado]="readonly"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="formacoes"
    campoDeTexto="descricao"
    permitirFiltroDePesquisa="false"
    #formacao>
  </dft-multiselect>
  
  <br/>

  <ng-container *ngIf="formacao.control?.value?.id === formacaoEnum.SE">
    <mat-label attr.aria-hidden="true">
      Grande Área de Formação
    </mat-label>
    <dft-multiselect
      campoIdentificador="codigo"
      formControlName="_grandeArea"
      placeholder="Selecione a Grande Área"
      [desabilitado]="readonly"
      [delineado]="true"
      [selecaoUnica]="true"
      [listaDeOpcoes]="grandesAreas$ | async"
      permitirFiltroDePesquisa="true"
      #campoGrandeArea>
    </dft-multiselect>

    <br />
    
    <mat-label attr.aria-hidden="true">
      Área Básica de Formação
    </mat-label>
    <dft-multiselect
      campoIdentificador="codigo"
      formControlName="_areaBasica"
      placeholder="Selecione a Área Básica"
      [desabilitado]="readonly || !campoGrandeArea.control?.value"
      [delineado]="true"
      [selecaoUnica]="true"
      [listaDeOpcoes]="areasBasicas$ | async"
      permitirFiltroDePesquisa="true"
      #campoAreaBasica>
    </dft-multiselect>
    
    <br />

    <mat-label attr.aria-hidden="true">
      Especialidade de Formação
    </mat-label>
    <dft-multiselect
      campoIdentificador="codigo"
      formControlName="_especialidade"
      placeholder="Selecione a Especialidade"
      [desabilitado]="readonly || !campoAreaBasica.control?.value"
      [delineado]="true"
      [selecaoUnica]="true"
      [listaDeOpcoes]="especialidades$ | async"
      permitirFiltroDePesquisa="true">
    </dft-multiselect>

    <br/>

    <mat-label attr.aria-hidden="true">
      <strong>Especialidades selecionadas</strong>
    </mat-label>
    <ul>
      <li *ngFor="let especialidade of form.controls.especialidades.value">
        {{ especialidade.descricao }}
        (<a 
          (click)="removerEspecialidade(especialidade)"
          (keyup)="removerEspecialidade(especialidade)">
          remover
        </a>)
      </li>
    </ul>
  </ng-container>
</form>
