<div class="row">
  <div class="col-md-4">
    <dft-multiselect
      [delineado]="false"
      [selecaoUnica]="true"
      [listaDeOpcoes]="listaDeCategoriasServico"
      ngModel
      (ngModelChange)="filtrarPorCategoriaServico($event)"
      placeholder="Categorias de serviço"
      permitirFiltroDePesquisa="true">
    </dft-multiselect>
  </div>
  <div class="col-md-6">
    <mat-form-field>
      <input matInput [(ngModel)]="filtro" placeholder="Filtrar" aria-label="Filtrar indicadores de produtividade" (keyup)="filtrar()"/>
      <button mat-button matSuffix mat-icon-button aria-label="Limpar filtro" (click)="limparFiltro()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="col-md-2">
    <button mat-raised-button color="primary" matSuffix  aria-label="Selecionar todos" (click)="selecionarTodos()">
      Selecionar todos
    </button>
  </div>
</div>

<form [formGroup]="form" *ngIf="indicadores$ | async as indicadores" autocomplete="off">
  <div *ngFor="let indicador of indicadores">
    <mat-checkbox 
      [checked]="isSelecionado(indicador.id)"
      (change)="selecionar(indicador.id)"
      >
      {{ indicador.nome }}
    </mat-checkbox>
  </div>
  
  <p role="alert" *ngIf="indicadores.length === 0">
    Não existem indicadores de produtividade para seleção.<br/>
    Limpe o filtro ou selecione uma ou mais categorias de serviço atípicas 
    para sua unidade no cadastro de Unidades.
  </p>
</form>

<br />
