import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PessoaPerfil } from '../models/pessoa-perfil';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class PessoaPerfilService {

  apiPessoas = `${environment.urlApi}/pessoas`;
  apiUnidades = `${environment.urlApi}/unidades`;
  apiPessoasPerfis = `${environment.urlApi}/pessoas-perfis`;

  constructor(private http: HttpClient) { }

  public listarPessoasPerfis(): Observable<PessoaPerfil[]> {
    return this.http.get<PessoaPerfil[]>(`${this.apiPessoasPerfis}`, httpOptions);
  }

  /**
   * Recupera os perfis da Pessoa pelo ID ou CPF.
   *
   * @param identificacao ID da pessoa ou CPF
   * @returns os perfis encontrados
   */
  public listarPerfisDePessoa(identificacao: number | string): Observable<PessoaPerfil[]> {
    return this.http.get<PessoaPerfil[]>(`${this.apiPessoas}/${identificacao}/perfis`);
  }

  public listarPessoaPerfilUnidade(idUnidade: number) {
    return this.http.get<PessoaPerfil[]>(`${this.apiUnidades}/${idUnidade}/perfis`);
  }

  public inserirPessoaPerfil(idUnidade: number, pessoaPerfil: PessoaPerfil) {
    return this.http.post<PessoaPerfil>(`${this.apiUnidades}/${idUnidade}/perfis`, pessoaPerfil, httpOptions);
  }

  public deletarPessoaPerfil(pessoaPerfil: PessoaPerfil) {
    return this.http.delete<PessoaPerfil>(`${this.apiUnidades}/${pessoaPerfil.idUnidade}/perfis/${pessoaPerfil.idDto}`);
  }

}
