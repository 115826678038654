<form [formGroup]="form">
  <input type="hidden" formControlName="id" />

  <mat-label *ngIf="!readonly">
    Categoria de serviço
  </mat-label>
  <dft-multiselect
    formControlName="categoriaServico"
    placeholder="Selecione a Categoria de Serviço"
    [desabilitado]="readonly"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="categorias$ | async"
    ordenacao="descricao"
    textoSemDadosDisponiveis="Nenhuma categoria de serviço ATÍPICA foi cadastrada">
  </dft-multiselect>

  <br />

  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Nome do Indicador
    </mat-label>
    <input aria-label="Nome do Indicador"
      matInput
      name="nome"
      placeholder="Ex.: Lista de Demandas Externas"
      formControlName="nome"
      [readonly]="readonly"
      maxlength="5000"
      required
    />
  </mat-form-field>

  <br />

  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Código do Indicador de Produtividade
    </mat-label>
    <input aria-label="Código do Indicador de Produtividade com 8 dígitos"
      matInput
      type="text"
      mask="0000.0000"
      name="codigo"
      formControlName="codigo"
      maxlength="9"
      required
    />
  </mat-form-field>

  <mat-label *ngIf="isGestorGeral">
    Situação:
  </mat-label>
  <dft-multiselect *ngIf="isGestorGeral"
    formControlName="status"
    [listaDeOpcoes]="statusIndicadorMap"
    placeholder="Selecione a situação."
    [desabilitado]="readonly"
    [delineado]="true"
    [selecaoUnica]="true"
    permitirFiltroDePesquisa="false"
    ordenacao="descricao">
  </dft-multiselect>
</form>
