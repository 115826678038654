<form [formGroup]="form" class="container">

  <input type="hidden" formControlName="id" />

  <mat-form-field appearance="outline">
    <mat-label for="pessoa" attr.aria-hidden="true">Pessoa</mat-label>
    <input
      matInput
      name="pessoa"
      aria-label="Nome da pessoa"
      [value]="form.controls['pessoa']?.value"
      disabled
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">E-mail</mat-label>
    <input aria-label="E-mail"
      matInput
      id="campoEmailFormulario"
      pattern="[a-zA-Z0-9._-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
      name="email"
      formControlName="email"
      placeholder="Ex.: fulano@dominio.com.br"
      required
    />
  </mat-form-field>

  <br />

  <mat-grid-list cols="2" rowHeight="80px">
    <mat-grid-tile>
      <mat-form-field appearance="outline">
        <mat-label attr.aria-hidden="true">Matrícula</mat-label>
        <input
          aria-label="Matrícula"
          matInput
          id="campoMatriculaFormulario"
          formControlName="matricula"
          name="matricula"
          maxlength="12"
          placeholder="Ex.: 00.111.222-3"
          required
        />
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field appearance="outline">
        <mat-label attr.aria-hidden="true">Carga Horária Semanal</mat-label>
        <input
          aria-label="Carga Horária Semanal"
          matInput
          formControlName="cargaHoraria"
          name="cargaHoraria"
          type="number"
          pattern="[0-9]{1,2}"
          placeholder="Ex.: 40"
          required
        />
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-label attr.aria-hidden="true">Situação Funcional</mat-label>
  <dft-multiselect
    formControlName="situacaoFuncional"
    placeholder="Selecione a situação funcional"
    campoDeTexto="nome"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="situacoesFuncionais$ | async">
  </dft-multiselect>

  <br/>
  
  <mat-label attr.aria-hidden="true">Vínculo</mat-label>
  <dft-multiselect
    formControlName="vinculo"
    placeholder="Selecione o vínculo"
    campoDeTexto="nome"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="vinculos$ | async">
  </dft-multiselect>
  
  <br/>

  <mat-label attr.aria-hidden="true">Gratificação</mat-label>
  <dft-multiselect
    formControlName="gratificacao"
    placeholder="Selecione a gratificação"
    campoDeTexto="nome"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="gratificacoes$ | async">
  </dft-multiselect>

  <br />

  <mat-label attr.aria-hidden="true">Cargo</mat-label>
  <dft-multiselect
    formControlName="cargo"
    placeholder="Selecione o cargo"
    campoDeTexto="nome"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="cargos$ | async">
  </dft-multiselect>

  <br />

  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">Data de Ingresso no Serviço Público</mat-label>
    <input aria-label="Data de Ingresso no Serviço Público"
      matInput
      placeholder="Ex.: 01/05/2017"
      name="dataIngressoServPublico"
      [matDatepicker]="dtIngressoServPublico"
      formControlName="dataIngressoServPublico"
    />
    <mat-datepicker-toggle matSuffix [for]="dtIngressoServPublico"></mat-datepicker-toggle>
    <mat-datepicker #dtIngressoServPublico startView="multi-year"> </mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">Data de Ingresso no Órgão</mat-label>
    <input aria-label="Data de Ingresso no Órgão"
      matInput
      placeholder="Ex.: 10/08/2018"
      name="dataIngressoOrgao"
      [matDatepicker]="dtIngressoOrgao"
      formControlName="dataIngressoOrgao"
    />
    <mat-datepicker-toggle matSuffix [for]="dtIngressoOrgao"></mat-datepicker-toggle>
    <mat-datepicker #dtIngressoOrgao startView="multi-year"> </mat-datepicker>
  </mat-form-field>

  <mat-label attr.aria-hidden="true">É optante do PGD/Plano de Trabalho:</mat-label>
  <br/>
  <mat-slide-toggle color="primary" formControlName="programaPGD"></mat-slide-toggle>

  <br/>
  <br/>

</form>