import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TipoAutenticador } from '@dft/shared/enums/tipo-autenticador.enum';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthService } from 'src/app/security/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Credenciais } from '../../../security/auth/credenciais';

const TAMANHO_MIN_TEXTO = 2;
const TAMANHO_CPF = 11;
const TAMANHO_MAXIMO_LOGIN = 100;

@Component({
  selector: 'dft-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {

  @ViewChild('form', { static: true })
  private form: ElementRef<HTMLFormElement>;

  @ViewChild('inputLogin')
  private inputLogin: ElementRef<HTMLInputElement>;

  loginLabel = 'Digite seu CPF';
  loginField = new FormControl('', [
    Validators.required,
    Validators.minLength(TAMANHO_CPF),
    Validators.maxLength(TAMANHO_CPF),
    Validators.pattern('^[0-9]*$')
  ]);
  loginFieldMaxLength = TAMANHO_CPF;
  loginFieldMinLength = TAMANHO_CPF;
  senhaField = new FormControl('', [Validators.required]);
  recaptcha = new FormControl('');
  exibirFormRecuperarSenha = false;

  constructor(
    private authService: AuthService,
    private recaptchaService: ReCaptchaV3Service,
  ) { }

  ngOnInit(): void {
    if (environment.autenticacao.loginTexto) {
      this.loginLabel = 'Digite seu login';
      this.loginField.setValidators([Validators.required]);
      this.loginFieldMaxLength = TAMANHO_MAXIMO_LOGIN;
      this.loginField.updateValueAndValidity();
    }
  }

  get exibirCamposLogin() {
    return environment.autenticacao.tipo === TipoAutenticador.LOCAL;
  }

  get botaoEntrarDesabilitado() {
    return environment.autenticacao.tipo === TipoAutenticador.LOCAL &&
      (this.loginField.invalid || this.senhaField.invalid);
  }

  autenticar() {
    // Se form de login habilitado, a autenticação
    // será feita localmente (via POST para o próprio DFT)
    if (this.exibirCamposLogin) {
      this.autenticarComCredenciais();
      return;
    }

    // Encaminha para o mecanismo de autenticação padrão
    this.authService.login();
  }

  recuperar(event: any) {
    event.preventDefault();
    this.loginField.markAsTouched();

    if (this.loginField.valid) {
      this.recaptchaService.execute('login').subscribe(token => {
        this.recaptcha.setValue(token);
        this.form.nativeElement.action = environment.redirectUrl + '?recuperar';
        this.form.nativeElement.submit();
      });
    }
  }

  exibirFormRecuperar() {
    this.exibirFormRecuperarSenha = true;
    this.inputLogin.nativeElement.focus();
  }

  cancelarRecuperar() {
    this.exibirFormRecuperarSenha = false;
    this.inputLogin.nativeElement.focus();
  }

  private async autenticarComCredenciais() {
    this.loginField.markAsTouched();
    this.senhaField.markAsTouched();

    // Verificando status dos campso do formulário
    if (this.loginField.invalid || this.senhaField.invalid) {
      return;
    }

    const credenciais: Credenciais = {
      login: this.loginField.value,
      senha: this.senhaField.value,
    };
    const isRecaptchaHabilitado = await this.authService.isCaptchaHabilitado().toPromise();
    if (isRecaptchaHabilitado) {
      const token = await this.recaptchaService.execute('login').toPromise();
      this.authService.login({ ...credenciais, recaptcha: token });
      return;
    }
    this.authService.login(credenciais);
  }

  validarMinCaracteres(value: string): void{
    if (value.match('^[0-9]*$')){
      // Apenas números
      this.loginFieldMinLength = TAMANHO_CPF;
    } else {
      this.loginFieldMinLength = TAMANHO_MIN_TEXTO;
    }
  }
}
