<form [formGroup]="form">

  <mat-grid-list cols="6" rowHeight="80px">
    <mat-grid-tile colspan="2" >
      <mat-form-field appearance="outline" >
        <mat-label aria-hidden="true">Código da Entrega</mat-label>
        <input aria-label="Código da Entrega com 6 dígitos"
          matInput
          name="codigo"
          mask="000.000"
          formControlName="apelidoCodigo"
        />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="4">
      <mat-form-field appearance="outline">
        <mat-label>Entrega</mat-label>
        <input matInput type="text" formControlName="apelidoEntrega" />
      </mat-form-field>
    </mat-grid-tile >
  </mat-grid-list>

  <mat-form-field appearance="outline">
    <mat-label>Atividade</mat-label>
    <input matInput type="text" formControlName="apelidoAtividade" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Fluxo de Trabalho</mat-label>
    <textarea
      matInput
      cdkTextareaAutosize
      cdkAutosizeMinRows="4"
      cdkAutosizeMaxRows="5"
      formControlName="apelidoFluxoTrabalho"
    ></textarea>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Fonte</mat-label>
    <input matInput type="text" formControlName="apelidoFonte" />
  </mat-form-field>
  
</form>
