<nav class="menu-container" *ngIf="menus" >
  <ul tabindex="-1" style="width:100%;display: flex;justify-content: center;" dft-menu nzMode="horizontal">
    <li tabindex="0" *ngFor="let menu of menus; let i=index" dft-submenu overlayPosition="{{(i > menus.length / 2 && isWidth600) ? 'bottomRight' : 'bottomLeft'}}">
      <span title><i aria-hidden="true" class="{{menu.icone}} icone-titulo" ></i><span class="titulo-externo">{{menu.titulo}}</span></span>
        <ul dft-menu-group tabindex="-1">
          <span class="titulo-interno" title>{{menu.titulo}}</span>
          <li  tabindex="0" *ngFor="let opcao of menu.opcoes" dft-menu-item nzMatchRouter><a [routerLink]="opcao.link">{{opcao.titulo}}</a></li>
        </ul>
    </li>
  </ul>
</nav>