import { Injectable, Type } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter, map } from 'rxjs/operators';
import { DialogFormComponent } from './dialog-form.component';
import { DialogComponent } from './dialog.component';


export interface AlertConfig {
  title?: string;
  content?: string;
  close: string;
}

export interface ConfirmConfig {
  title?: string;
  content?: string;
  ok?: string;
  close: string;
}

export type SetValueFunc = (value: object) => void;
export type SetReadonlyFunc = (readonly: boolean) => void;

export interface FormComponent {
  form: FormGroup;
  setFormValue: SetValueFunc;
  setFormReadOnly: SetReadonlyFunc;
}

export interface PromptConfig {
  formComponent: Type<FormComponent>;
  title?: string;
  content?: string;
  value?: object;
  readOnly?: boolean;
  ok?: string;
  close: string;
  okIcon?: string;
  closeIcon?: string;
  acaoAdicionalLabel?: string;
  acaoAdicionalIcon?: string;
  acaoAdicionalColor?: string;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) { }

  alert(alert: AlertConfig) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: alert,
    });
    return dialogRef.afterClosed();
  }

  confirm(confirm: ConfirmConfig) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: confirm,
    });
    return dialogRef.afterClosed().pipe(map(Boolean));
  }

  prompt(prompt: PromptConfig, fixWidth?: number) {
    const dialogRef = this.dialog.open(DialogFormComponent, {
      data: prompt,
      minWidth: fixWidth
    });
    return dialogRef.afterClosed().pipe(filter(Boolean));
  }
}
