import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  TipoMetodologiaEnum,
  TIPO_METODOLOGIA_MAP
} from '@dft/shared/enums/metodologia.enum';
import { Dimensionamento } from '@dft/shared/models/dimensionamento';
import { DimensionamentoSimples } from '@dft/shared/models/dimensionamento-simples';
import { Orgao } from '@dft/shared/models/orgao';
import { Unidade } from '@dft/shared/models/unidade';
import { DimensionamentoService } from '@dft/shared/services/dimensionamento.service';
import { OrgaoService } from '@dft/shared/services/orgao.service';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

interface Metodologia {
  id: number;
  descricao: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'dimensionamento-form.component.html',
  styleUrls: ['dimensionamento-form.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DimensionamentoFormComponent implements OnInit {
  @ViewChild('descricao', { static: true })
  descricaoElementRef: ElementRef;

  form: FormGroup;
  formValue = null;
  readonly = false;

  listaDeDimensionamentos: DimensionamentoSimples[] = [];
  dimensionamentoCloneSelecionado: DimensionamentoSimples = new DimensionamentoSimples();
  private dimensionamentosOriginal: DimensionamentoSimples[] = [];

  listaDeUnidades: Observable<Unidade[]>;
  listaDeOrgaos: Observable<Orgao[]>;
  listaDeMetodologias: Metodologia[] = [
    { id: 0, descricao: TIPO_METODOLOGIA_MAP.get(TipoMetodologiaEnum.TIPICA) },
    { id: 1, descricao: TIPO_METODOLOGIA_MAP.get(TipoMetodologiaEnum.ATIPICA) }
  ];

  constructor(
    private orgaoService: OrgaoService,
    private dimensionamentoService: DimensionamentoService,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(''),
      orgao: this.formBuilder.control(null, Validators.required),
      unidade: this.formBuilder.control(null, Validators.required),
      descricao: this.formBuilder.control('', [Validators.maxLength(2000)]),
      dataInicio: this.formBuilder.control(null, Validators.required),
      dataFim: this.formBuilder.control(null, Validators.required),
      tipo: this.formBuilder.control('', Validators.required),
      dimensionamentoClonado: this.formBuilder.control(null),
      isMetaAtivo: this.formBuilder.control(false),
      registroProducao: this.formBuilder.control(false),
      inPlanoTrabalho: this.formBuilder.control(false),
    });
  }

  obtemMensagemDeErro() {
    return this.form.controls['descricao'].hasError('required')
      ? 'O campo Observação é obrigatório.'
      : this.form.controls['descricao'].hasError('maxlength')
        ? 'Observação não pode conter mais que 2000 caracteres.'
        : '';
  }

  ngOnInit() {
    if (this.readonly) {
      const dimensionamento: Dimensionamento = this.formValue.dimensionamento;
      this.listaDeOrgaos = of([dimensionamento.unidade.orgao]);
      this.listaDeUnidades = of([dimensionamento.unidade]);
      this.preencheFormulario(dimensionamento);

    } else {
      this.preencherListaDeOrgaos();
      this.preencherListaDimensionamentosOriginal();

      // Adicionando listeners de change
      this.form.controls.orgao.valueChanges.subscribe((orgao) => {
        this.atualizarUnidades(orgao);
      });
      this.form.controls.tipo.valueChanges.subscribe(tipo => {
        this.atualizarDimensionamentosClonaveis(tipo);
      });
      this.form.controls.dimensionamentoClonado.valueChanges.subscribe(
        (dim) => this.dimensionamentoCloneSelecionado = dim
      );
    }
  }

  private preencheFormulario(dimensionamento: Dimensionamento) {
    this.listaDeOrgaos = this.listaDeOrgaos.pipe(
      map((o) => this.montarBindName(o))
    );
    this.listaDeUnidades = this.listaDeUnidades.pipe(
      map((u) => this.montarBindName(u))
    );
    this.form.controls['orgao'].setValue(dimensionamento.unidade.orgao);
    this.form.controls['unidade'].setValue(dimensionamento.unidade);
    this.form.controls['descricao'].setValue(dimensionamento.descricao);
    this.form.controls['dataInicio'].setValue(dimensionamento.dataInicio);
    this.form.controls['dataInicio'].disable();
    this.form.controls['dataFim'].setValue(dimensionamento.dataFim);
    this.form.controls['dataFim'].disable();
    this.form.controls['tipo'].setValue({
      id: dimensionamento.tipo === TipoMetodologiaEnum.TIPICA ? 0 : 1,
      descricao: TIPO_METODOLOGIA_MAP.get(dimensionamento.tipo),
    });
    this.form.controls['dimensionamentoClonado'].setValue(
      dimensionamento.dimensionamentoClone
    );
    this.form.controls['isMetaAtivo'].setValue(dimensionamento.metaAtivo);
    this.form.controls['registroProducao'].setValue(dimensionamento.registroProducao);
    this.form.controls['inPlanoTrabalho'].setValue(dimensionamento.inPlanoTrabalho);
  }

  private montarBindName(unidade: Unidade[]): Unidade[];
  private montarBindName(orgaos: Orgao[]): Orgao[] {
    return Orgao.montarBindName(orgaos);
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  private preencherListaDeOrgaos() {
    this.listaDeOrgaos = this.orgaoService.listarOrgaos().pipe(
      map((orgaos) => this.montarBindName(orgaos)),
      tap((orgaos) => {
        if (orgaos.length === 1) {
          this.form.controls.orgao.setValue(orgaos[0]);
        }
      })
    );
  }

  private preencherListaDimensionamentosOriginal() {
    this.dimensionamentoService
      .listarDimensionamentos()
      .subscribe(lista => {
        this.dimensionamentosOriginal = this.listaDeDimensionamentos = lista;
        this.listaDeDimensionamentos.sort((a, b) => {
          const orgaoCompare = a.unidade.orgao.sigla.localeCompare(b.unidade.orgao.sigla);
          if (orgaoCompare !== 0) {
            return orgaoCompare;
          }
          return a.unidade.sigla.localeCompare(b.unidade.sigla);
        });
      });
  }

  normalizaDataDigitada({ target }) {
    if (target.name === 'data-inicial') {
      this.form.controls.dataInicio.setValue(
        this.form.controls.dataInicio.value.format('YYYY-MM-DD')
      );
    } else {
      this.form.controls.dataFim.setValue(
        this.form.controls.dataFim.value.format('YYYY-MM-DD')
      );
    }
  }

  escolhaAnoInicioHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.form.controls.dataInicio.value
      ? moment(this.form.controls.dataInicio.value, 'YYYY-MM')
      : moment();
    ctrlValue.year(normalizedYear.year());
    this.form.controls.dataInicio.setValue(ctrlValue);
  }

  escolhaMesInicioHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue = this.form.controls.dataInicio.value
      ? moment(this.form.controls.dataInicio.value, 'YYYY-MM')
      : moment();
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.date(1);
    this.form.controls.dataInicio.setValue(ctrlValue);

    const dataInicioSelecionada = this.form.controls.dataInicio.value.format('YYYY-MM-DD');
    this.form.controls.dataInicio.setValue(dataInicioSelecionada);
    datepicker.close();
  }

  escolhaAnoFimHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.form.controls.dataFim.value
      ? moment(this.form.controls.dataFim.value, 'YYYY-MM')
      : moment();
    ctrlValue.year(normalizedYear.year());
    this.form.controls.dataFim.setValue(ctrlValue);
  }

  escolhaMesFimHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue = this.form.controls.dataFim.value
      ? moment(this.form.controls.dataFim.value, 'YYYY-MM')
      : moment();
    const dataFimSelecionada = ctrlValue
      .month(normalizedMonth.month())
      .date(1)
      .format('YYYY-MM-DD');
    this.form.controls.dataFim.setValue(ctrlValue);

    // this.dataFimSelecionada = this.form.controls.dataFim.value;
    this.form.controls.dataFim.setValue(dataFimSelecionada);

    datepicker.close();
  }

  private atualizarUnidades(orgao: Orgao) {
    this.listaDeUnidades = this.orgaoService
      .buscarUnidadesFilhas(orgao.id)
      .pipe(
        map(unidades => this.montarBindName(unidades)),
        tap(unidades => {
          if (unidades.length === 1) {
            this.form.controls.unidade.setValue(unidades[0]);
            this.descricaoElementRef.nativeElement.focus();
          }
        })
      );
  }

  private atualizarDimensionamentosClonaveis(tipo: Metodologia) {
    if (!tipo) {
      this.listaDeDimensionamentos = [];
      return;
    }
    if (tipo.descricao === TIPO_METODOLOGIA_MAP.get(TipoMetodologiaEnum.TIPICA)) {
      this.listaDeDimensionamentos = this.dimensionamentosOriginal.filter(
        (element) => element.tipo === TipoMetodologiaEnum.TIPICA
      );
    } else {
      this.listaDeDimensionamentos = this.dimensionamentosOriginal.filter(
        (element) => element.tipo === TipoMetodologiaEnum.ATIPICA
      );
    }
  }
}
